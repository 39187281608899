import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node"

import { Link, json, useLoaderData } from "@remix-run/react"
import { Fragment } from "react"

import { Blurhash } from "@/components/blurhash"
import { Button } from "@/components/button"
import { Organization } from "@/components/structured-data/organization"
import { CategoryController } from "@/controllers/category-controller.server"
import { PostController } from "@/controllers/post-controller.server"
import { cacheHeaders } from "@/lib/cacheHeaders"
import { ColorsCategory } from "@/lib/colors-category"
import { cn } from "@/lib/utils"

export const headers = () => cacheHeaders()

export const loader = async ({ context: { payload } }: LoaderFunctionArgs) => {
  const categoriesIds = [1, 2, 3, 4]
  const contextsIds = [1, 2, 3, 4, 5]
  const postController = PostController(payload)
  const categoryController = CategoryController(payload)
  const category = await categoryController.findBySlug("")
  const highlightedPosts = await postController.findLatestsNews({ page: 0, limit: 5 })
  const excludesIds = highlightedPosts.docs.map(post => post.id)
  const postsByCategory = await Promise.all(categoriesIds.map(async (categoryId) => postController.findByCategoryId({ categoryId, limit: 5, excludesIds })))
  const excludesIdsForContext = [
    ...postsByCategory.map(posts => posts.docs.map(post => post.id)).flat(),
    ...excludesIds
  ]
  const postsByContexts = await Promise.all(contextsIds.map(async (contextId) => postController.findByContextId({ contextId, limit: 3, excludesIds: excludesIdsForContext })))
  return json({
    meta: categoryController.formatMeta(category),
    highlightedPosts: postController.formatPosts(highlightedPosts.docs, 'home'),
    categoriesPosts: postsByCategory.map(posts => postController.formatPosts(posts.docs, 'home')),
    postsByContexts: postsByContexts.map(posts => postController.formatPosts(posts.docs, 'home'))
  })
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data) return []
  const { image, canonical, title, description } = data.meta
  return [
    { tagName: "link", rel: "canonical", href: canonical },
    { title: title },
    { name: "title", content: title },
    { name: "description", content: description },
    { property: "twitter:title", content: title },
    { property: "twitter:description", content: description },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:image", content: image?.url },
    { property: "twitter:image:width", content: image?.width },
    { property: "twitter:image:height", content: image?.height },
    { property: "twitter:url", content: canonical },
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:url", content: canonical },
    { property: "og:image", content: image?.url },
    { property: "og:image:width", content: image?.width },
    { property: "og:image:height", content: image?.height },
    { property: "og:type", content: "website" },
    {
      "script:ld+json": {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": title,
        "description": description,
        "url": canonical,
        "publisher": {
          ...Organization,
        }
      }
    }
  ]
}

export default function Element() {
  const { highlightedPosts, categoriesPosts, postsByContexts } = useLoaderData<typeof loader>()
  return (
    <div className="container mt-4 max-w-screen-lg flex flex-col gap-8">

      <section className="flex flex-col gap-4 my-6">
        <div className="grid lg:grid-cols-12 gap-x-8 gap-y-4">
          {highlightedPosts.map(({ id, title, media, pathname, category, excerpt }, index) => (
            <article className="flex flex-col gap-1.5 first:row-span-2 first:col-span-6 col-span-3 overflow-hidden" key={id}>
              {media && (
                <Link to={pathname}>
                  <Blurhash hash={media.blurhash} className="rounded-lg">
                    <img className="rounded-lg mb-2" src={media.url} alt={media.alt} width={media.width} height={media.height} />
                  </Blurhash>
                </Link>
              )}
              <h3 className={cn("font-medium hover:opacity-80", index === 0 ? "text-3xl" : "text-base", ColorsCategory[category.color])}><Link to={pathname}>{title}</Link></h3>
              {index === 0 && (
                <p className="mt-2 text-sm text-gray-800">{excerpt}</p>
              )}
            </article>
          ))}
        </div>
      </section>

      {categoriesPosts.map((posts, index) => (
        <section key={index} className="flex flex-col gap-4 mb-6">
          {posts[0]?.category && (
            <div className="flex justify-between items-center border-b pb-2 mb-2">
              <Link to={`${posts[0].category.slug}`}>
                <h2 className={cn("text-2xl font-medium hover:opacity-80", ColorsCategory[posts[0].category.color])}>{posts[0].category.title}</h2>
              </Link>
              <Button variant="outline" asChild>
                <Link to={`${posts[0].category.slug}`}>Veja mais notícias</Link>
              </Button>
            </div>
          )}
          <div className="grid lg:grid-cols-12 gap-x-8 gap-y-4">
            {posts.map(({ id, title, media, pathname, category, excerpt }, index) => (
              <article className="flex flex-col gap-1.5 first:row-span-2 first:col-span-6 col-span-3 overflow-hidden" key={id}>
                {media && (
                  <Link to={pathname}>
                    <Blurhash hash={media.blurhash} className="rounded-lg">
                      <img className="rounded-lg mb-2" src={media.url} alt={media.alt} width={media.width} height={media.height} />
                    </Blurhash>
                  </Link>
                )}
                <h3 className={cn("font-medium hover:opacity-80", index === 0 ? "text-3xl" : "text-base", ColorsCategory[category.color])}><Link to={pathname}>{title}</Link></h3>
                {index === 0 && (
                  <p className="mt-2 text-sm text-gray-800">{excerpt}</p>
                )}
              </article>
            ))}
          </div>
        </section>
      ))}

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
        {postsByContexts.map((posts, index) => (
          <section key={index} className="flex flex-col gap-4 mb-8">
            {posts[0]?.context && (
              <Link to={`${posts[0].context.slug}`}>
                <h2 className={cn("text-2xl font-medium border-b pb-2 mb-2 hover:opacity-80")}>{posts[0].context.title}</h2>
              </Link>
            )}
            <div className="flex flex-col gap-4">
              {posts.map(({ id, title, media, pathname, excerpt }, index) => (
                <Fragment key={id}>
                  {index === 0 ? (
                    <article>
                      {media && (
                        <Link to={pathname}>
                          <Blurhash hash={media.blurhash} className="rounded-lg">
                            <img className="rounded-lg" src={media.url} alt={media.alt} width={media.width} height={media.height} />
                          </Blurhash>
                        </Link>
                      )}
                      <h3 className="font-medium hover:opacity-80 text-xl mt-3 mb-1"><Link to={pathname}>{title}</Link></h3>
                      <p className="text-sm text-gray-800">{excerpt}</p>
                    </article>
                  ) : (
                    <article className="grid grid-cols-12 items-center gap-2 border-t pt-4">
                      <h3 className={cn("col-span-7 font-medium hover:opacity-80 text-lg lg:text-base")}><Link to={pathname}>{title}</Link></h3>
                      {media && (
                        <Link to={pathname} className="col-span-5">
                          <Blurhash hash={media.blurhash} className="rounded-lg">
                            <img className="rounded-lg" src={media.url} alt={media.alt} width={media.width} height={media.height} />
                          </Blurhash>
                        </Link>
                      )}
                    </article>
                  )}
                </Fragment>
              ))}
            </div>
          </section>
        ))}
      </div>
    </div >
  )
}